/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDDtTaehlGjpHxhzJ2yHi5cAKT_k2Kf1so",
  "appId": "1:1002629418214:web:3c6ac602da10f377f4ff27",
  "authDomain": "schooldog-i-dade-ga.firebaseapp.com",
  "measurementId": "G-R5LMT66HT0",
  "messagingSenderId": "1002629418214",
  "project": "schooldog-i-dade-ga",
  "projectId": "schooldog-i-dade-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dade-ga.appspot.com"
}
